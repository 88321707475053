/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';

import Swal from 'sweetalert2';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
  Link
} from '@mui/material';
import config from '@configFile';
import { useSendMailing } from '@hooks/useSendMailing';
import { useGetUserData } from '@hooks/useGetUserData';
import { useGetDealSummaryNonEnabled } from '@hooks/useGetDealSummary';
import { useGetDealListForItem } from '@hooks/useDealLists';
import { useUpdateDeal } from '@hooks/useUpdateDeal';
import { useSnackbar } from '@hooks/useSnackbar';
import { useGetAIPostText } from '@hooks/useAI';
import { AdminFeatures } from '../AdminActions/FeaturesModal';
import {
  stopPropagationForTab,
  addPrefixToStringIfNotAlready
} from '../../utils';
import postBeginnings from '../../utils/dealPostOptions';
import { allMailingListsOptions } from '../../utils/mailingLists';
import queryClient from '../../queryClient';
import SeeStatsContent from '../AdminActions/components/SeeStatsContent';
import DealListChanger from '../AdminActions/components/DealListChanger';
import Loading from '../Loading';
import SelectAdditionalImages from './components/SelectAdditionalImages';
import SelectMainImage from './components/SelectMainImage';
import SentModal from './components/SentModal';
import MailingHistory from './components/MailingHistory';
import PresetEmailBeginning from './components/PresetEmailBeginning';
import SendMailingButton from './components/SendMailingButton';
import PostText from './components/PostText';
import {
  isUserInPSTTimeZone,
  suggestTimeInPST,
  fiveMinutesFromNow
} from './timeUtils';
import CouponHasBeenEmailed from './components/CouponHasBeenEmailed';

type Value = Date | null;

const SendMailing = ({
  ASIN,
  slug,
  subject: _subject,
  appearance = 'button',
  onCloseCallback = () => {},
  promoCode,
  defaultEmailText = '',
  otherMailingType
}: {
  ASIN?: string;
  slug?: string;
  promoCode?: string;
  otherMailingType?: string | null;
  subject: string;
  appearance?: 'button' | 'menuItem' | 'iconButton';
  defaultEmailText?: string;
  onCloseCallback?: () => void;
}) => {
  const [scheduleAt, setScheduleAt] = useState<Value>(fiveMinutesFromNow);
  const [shouldBlogLinksBePageLink, setShouldBlogLinksBePageLink] =
    useState(false);
  const mailingLists = allMailingListsOptions;
  const randomPostBeginning =
    postBeginnings[Math.floor(Math.random() * postBeginnings.length)];
  const subjectWithPrefix = addPrefixToStringIfNotAlready(_subject);
  const { data: dealListsForItem, refetch: fetchDealListsForItem } =
    useGetDealListForItem(ASIN);
  const [sentModalOpen, setSentModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showVariations, setShowVariations] = useState(false);
  const [showVariationsThreshold, setShowVariationsThreshold] = useState('');
  const [emailBeginning, setEmailBeginning] = useState(randomPostBeginning);
  const [mainImage, setMainImage] = useState<string | null>(null);
  const [additionalPostText, setAdditionalPostText] =
    useState(defaultEmailText);
  const [sentThumbnailUrl, setSentThumbnailUrl] = useState<string | null>(null);
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);
  const [emailBeginningSelect, setEmailBeginningSelect] =
    useState(randomPostBeginning);
  const {
    data: dealSummary,
    refetch: fetchDealSummary,
    isLoading: isDealSummaryLoading
  } = useGetDealSummaryNonEnabled(ASIN);
  const highResImages = dealSummary?.highResImages?.split(',') || [];
  const [mailingListId, setMailingListId] = useState(
    config.sendGrid.mailingLists.test
  );
  const [subject, setSubject] = useState(subjectWithPrefix);
  const { data: user } = useGetUserData();
  const { showMessage } = useSnackbar();
  const { mutate: sendMailing, isLoading: isSending } = useSendMailing();
  const { mutate: updateDeal, isLoading: isUpdating } = useUpdateDeal();
  const { mutate: getAIPostText, isLoading: isLoadingAIPostText } =
    useGetAIPostText();

  const showError = async (errorText: string = '') => {
    await Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: errorText,
      footer:
        '<a href="https://www.jungle.deals/deal-lists?tab=7">Why do I have this issue?</a>'
    });
  };

  useEffect(() => {
    if (ASIN && modalOpen) {
      fetchDealSummary();
      fetchDealListsForItem();
      setScheduleAt(new Date(new Date().getTime() + 10 * 60000));
    }
  }, [ASIN, modalOpen]);

  useEffect(() => {
    if (dealSummary) {
      const dealHighResImages = dealSummary?.highResImages?.split(',') || [];
      const newMainImage =
        dealSummary?.image600Url || dealSummary?.image || dealHighResImages[0];

      setMainImage(newMainImage);
      setAdditionalPostText(dealSummary?.postText || '');
    }
  }, [dealSummary]);

  const handleModalClose = (_event: React.MouseEvent, reason: string) => {
    if (reason !== 'backdropClick') {
      setModalOpen(false);
      setAdditionalPostText(dealSummary?.postText || '');
      onCloseCallback();
    }
  };

  const handleModalClickClose = () => {
    setModalOpen(false);
    onCloseCallback();
  };

  const getMailingParams = () => {
    return {
      slug,
      ASIN,
      promoCode,
      mailingListId,
      subject,
      options: {
        showVariations,
        showVariationsThreshold,
        emailBeginning,
        additionalImages,
        shouldBlogLinksBePageLink,
        mainImage,
        additionalPostText,
        scheduleAt,
        otherMailingType
      }
    };
  };

  const processSucccess = async (data: {
    hasSentMailing?: boolean;
    thumbnailUrl?: string;
    error?: string;
  }) => {
    if (data?.hasSentMailing) {
      setSentModalOpen(true);
      if (data?.thumbnailUrl) {
        setSentThumbnailUrl(data?.thumbnailUrl);
      }
      queryClient.refetchQueries('mailing-recommendations');
      if (scheduleAt) {
        queryClient.refetchQueries('scheduled-emails');
      }
    } else if (data?.error) {
      await showError(data?.error);
    } else {
      await showError();
    }
    queryClient.refetchQueries('sent-mailings');
  };

  const handleSendMailingClick = () => {
    sendMailing(
      {
        ...getMailingParams()
      },
      {
        onSuccess: async ({ data }) => {
          await processSucccess(data);
        },
        onError: async () => {
          await showError();
        }
      }
    );
  };

  if (!user?.isAdmin || (!slug && !ASIN && !promoCode && !otherMailingType)) {
    return null;
  }

  const renderSelectPostBeginning = () => {
    return (
      <PresetEmailBeginning
        value={emailBeginningSelect}
        onChange={(e) => {
          setEmailBeginning(e.target.value as string);
          setEmailBeginningSelect(e.target.value as string);
        }}
      />
    );
  };

  const handleWillSellOutClick = () => {
    // Add *WILL SELL OUT* to current subject
    setSubject(`*WILL SELL OUT* ${subject}`);
  };

  const renderSentModal = () => {
    if (sentModalOpen) {
      return (
        <SentModal
          onCloseCallback={() => {
            setSentModalOpen(false);
            handleModalClickClose();
          }}
          sentThumbnailUrl={sentThumbnailUrl || ''}
          scheduleAt={scheduleAt?.toString() || ''}
        />
      );
    }

    return null;
  };

  const schedule10Mins = () => {
    setScheduleAt(new Date(new Date().getTime() + 10 * 60000));
  };

  const renderMainImageSelector = () => {
    if (!ASIN) {
      return null;
    }

    if (isDealSummaryLoading) {
      return (
        <Box>
          <Box>Loading...</Box>
          <Divider />
        </Box>
      );
    }

    const images = dealSummary?.highResImages?.split(',') || [];

    if (images.length === 0) {
      return null;
    }

    // render a dropdown for choosing a single main image from the highResImages
    // and show the image above on change

    return (
      <SelectMainImage
        value={mainImage || ''}
        onChange={(e) => {
          setMainImage(e.target.value);
          if (additionalImages.includes(e.target.value)) {
            setAdditionalImages(
              additionalImages.filter((img) => img !== e.target.value)
            );
          }
        }}
        ASIN={ASIN}
        allImages={[dealSummary.image600Url, ...images]}
      />
    );
  };

  const renderAdditionalImageSelector = () => {
    const uniqueImages = [...new Set(highResImages || [])];

    if (!ASIN || isDealSummaryLoading || uniqueImages.length === 0) {
      return null;
    }

    return (
      <SelectAdditionalImages
        ASIN={ASIN}
        uniqueImages={uniqueImages}
        additionalImages={additionalImages}
        setAdditionalImages={setAdditionalImages}
        mainImage={mainImage || ''}
      />
    );
  };

  const renderSendMailingModalActions = () => {
    return (
      <DialogActions>
        <Button disabled={isSending} onClick={handleModalClickClose}>
          Cancel
        </Button>
        <Button
          disabled={isSending}
          onClick={handleSendMailingClick}
          variant="contained"
          color="primary"
        >
          {isSending ? 'Sending...' : 'Send'}
        </Button>
      </DialogActions>
    );
  };

  const renderSelectMailingList = () => {
    return (
      <FormControl margin="normal" fullWidth>
        <InputLabel id="select-mailing-list">Type</InputLabel>
        <Select
          labelId="select-mailing-list"
          value={mailingListId}
          onChange={(e) => setMailingListId(e.target.value as string)}
        >
          {mailingLists.map((section) => (
            <MenuItem key={section.value} value={section.value}>
              {section.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderSubject = () => {
    return (
      <FormControl margin="normal" fullWidth>
        <TextField
          multiline
          rows={3}
          placeholder="Subject"
          value={subject}
          error={subject.length > 140}
          onChange={(e) => setSubject(e.target.value)}
          helperText={`${subject.length}/140 ${
            subject.length > 140 ? '❌' : '✅'
          }`}
          autoComplete="off"
        />
      </FormControl>
    );
  };

  const renderWillSellOut = () => {
    if (!ASIN) {
      return null;
    }
    return (
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={handleWillSellOutClick}
          disabled={subject.includes('WILL SELL OUT')}
        >
          Will sell out
        </Button>
      </Box>
    );
  };

  const renderPostText = () => {
    if (!ASIN && !promoCode && !otherMailingType) {
      return null;
    }
    return (
      <PostText
        additionalPostText={additionalPostText}
        setAdditionalPostText={setAdditionalPostText}
        ASIN={ASIN}
        isUpdating={isUpdating}
        getAIPostText={() => {
          getAIPostText(
            {
              ASIN
            },
            {
              onSuccess: ({ suggestion }) => {
                setAdditionalPostText(suggestion);
                showMessage('Suggestion applied');
              }
            }
          );
        }}
        otherMailingType={otherMailingType}
        isLoadingAIPostText={isLoadingAIPostText}
        updatePostText={() => {
          updateDeal(
            {
              ASIN,
              update: {
                post_text: additionalPostText
              }
            },
            {
              onSuccess: () => {
                showMessage('Post text updated');
              }
            }
          );
        }}
      />
    );
  };

  const renderDealBeginning = () => {
    if (!ASIN) {
      return null;
    }
    return (
      <>
        {renderSelectPostBeginning()}
        <FormControl margin="normal" fullWidth>
          <TextField
            placeholder="Deal beginning text"
            value={emailBeginning}
            error={emailBeginning.length === 0}
            onChange={(e) => setEmailBeginning(e.target.value)}
            autoComplete="off"
          />
        </FormControl>
      </>
    );
  };

  const renderMailingTime = () => {
    return (
      <Box>
        <Box>
          <Button
            onClick={() => {
              const suggestion = suggestTimeInPST();
              setScheduleAt(suggestion);
            }}
          >
            Suggest time
          </Button>
          <Button onClick={schedule10Mins}>In 10 mins</Button>
        </Box>
        <Box marginBottom="40px" marginTop="10px">
          <DateTimePicker
            minDate={isUserInPSTTimeZone() ? new Date() : undefined}
            onChange={setScheduleAt}
            value={scheduleAt}
          />
        </Box>
      </Box>
    );
  };

  const renderBlogLinkToPageLink = () => {
    if (ASIN || promoCode || otherMailingType) {
      return null;
    }

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={shouldBlogLinksBePageLink}
            onChange={() => {
              setShouldBlogLinksBePageLink(!shouldBlogLinksBePageLink);
            }}
          />
        }
        label="Set blog links to page link"
      />
    );
  };

  const renderHasBeenEmailed = () => {
    if (dealSummary?.promoCode || promoCode) {
      return (
        <CouponHasBeenEmailed promoCode={dealSummary?.promoCode || promoCode} />
      );
    }

    return null;
  };

  const renderVariationsThreshold = () => {
    if (!ASIN || !dealSummary?.parentASIN || !showVariations) {
      return null;
    }

    return (
      <FormControl margin="normal" fullWidth>
        <TextField
          placeholder="Variations threshold"
          value={showVariationsThreshold}
          error={parseInt(showVariationsThreshold, 10) < 0}
          onChange={(e) => setShowVariationsThreshold(e.target.value)}
          autoComplete="off"
        />
      </FormControl>
    );
  };

  const renderSeeVariations = () => {
    if (!ASIN || !dealSummary?.parentASIN || !showVariations) {
      return null;
    }

    return (
      <Link
        href={`/variations?ASIN=${dealSummary?.parentASIN}`}
        target="_blank"
      >
        See variations
      </Link>
    );
  };

  const renderShowVariations = () => {
    if (!ASIN) {
      return null;
    }

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={showVariations}
            onChange={() => {
              setShowVariations(!showVariations);
            }}
          />
        }
        label="Show variations"
      />
    );
  };

  const renderRelatedDealLists = () => {
    if (!dealListsForItem) {
      return null;
    }

    return (
      <Typography variant="body1">
        Related deal lists: {dealListsForItem?.length}
      </Typography>
    );
  };

  const renderModalContent = () => {
    return (
      <Box>
        {renderRelatedDealLists()}
        {ASIN && <DealListChanger ASIN={ASIN} />}
        <MailingHistory ASIN={ASIN} slug={slug} promoCode={promoCode} />
        {ASIN && <SeeStatsContent ASIN={ASIN} />}
        {renderHasBeenEmailed()}
        {ASIN && <AdminFeatures ASIN={ASIN} />}
        {renderShowVariations()}
        {renderSeeVariations()}
        {renderMainImageSelector()}
        {renderAdditionalImageSelector()}
        {renderVariationsThreshold()}
        {renderMailingTime()}
        {renderBlogLinkToPageLink()}
        {renderDealBeginning()}
        {renderPostText()}
        {renderWillSellOut()}
        {renderSubject()}
        {renderSelectMailingList()}
      </Box>
    );
  };

  const renderSendMailingModal = () => {
    if (modalOpen) {
      return (
        <Dialog
          open
          onClose={handleModalClose}
          fullWidth
          onKeyDown={stopPropagationForTab}
          maxWidth="lg"
        >
          <DialogTitle>Send Mailing</DialogTitle>
          <DialogContent>
            {isSending ? <Loading /> : renderModalContent()}
          </DialogContent>
          {renderSendMailingModalActions()}
        </Dialog>
      );
    }

    return null;
  };

  return (
    <>
      {renderSendMailingModal()}
      {renderSentModal()}
      <SendMailingButton
        handleSendMailingButtonClick={() => {
          // fetchMailingListHistory();
          setModalOpen(true);
        }}
        appearance={appearance}
      />
    </>
  );
};

export default SendMailing;
