import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { DealPostType } from '../types';

const queryDealSummary = async (ASIN: string): Promise<DealPostType> => {
  const result = await axios.get(`/api/get-deal-summary?ASIN=${ASIN}`);
  return result.data;
};

export function useGetDealSummary(ASIN: string): UseQueryResult<DealPostType> {
  return useQuery<DealPostType, Error>(
    `deal-summary-${ASIN}`,
    () => queryDealSummary(ASIN),
    {
      onError: () => {
        console.error('Something went wrong while fetching deal summary');
      }
    }
  );
}

export function useGetDealSummaryNonEnabled(
  ASIN: string
): UseQueryResult<DealPostType> {
  return useQuery<DealPostType, Error>(
    `deal-summary-${ASIN}`,
    () => queryDealSummary(ASIN),
    {
      enabled: false,
      onError: () => {
        console.error('Something went wrong while fetching deal summary');
      }
    }
  );
}

export function useGetDealSummaryAdmin(
  ASIN: string
): UseQueryResult<DealPostType> {
  const queryDealSummaryAdmin = async (ASIN: string): Promise<DealPostType> => {
    const result = await axios.get(
      `/api/dashboard/get-deal-summary-admin?ASIN=${ASIN}`
    );
    return result.data;
  };

  return useQuery<DealPostType, Error>(
    `deal-summary-admin-${ASIN}`,
    () => queryDealSummaryAdmin(ASIN),
    {
      enabled: false,
      onError: () => {
        console.error('Something went wrong while fetching deal summary');
      }
    }
  );
}

export function useGetDealSummaryAdminEnabled(
  ASIN: string
): UseQueryResult<DealPostType> {
  const queryDealSummaryAdmin = async (ASIN: string): Promise<DealPostType> => {
    const result = await axios.get(
      `/api/dashboard/get-deal-summary-admin?ASIN=${ASIN}`
    );
    return result.data;
  };

  return useQuery<DealPostType, Error>(
    `deal-summary-admin-${ASIN}`,
    () => queryDealSummaryAdmin(ASIN),
    {
      onError: () => {
        console.error('Something went wrong while fetching deal summary');
      }
    }
  );
}
