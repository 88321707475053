import React from 'react';
import { format, formatDistance } from 'date-fns';
import { Tooltip } from '@mui/material';
import { formatPrice } from '@utils/index';

export interface SeeStatsContentProps {
  ASIN: string;
  maxHeight?: number;
}

export interface Column {
  id: 'name' | 'data';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

export const createRows = (dealSummaryAdmin: any) => {
  if (!dealSummaryAdmin) return [];

  const formatDateTooltip = (date: Date) => (
    <Tooltip title={format(new Date(date), 'EEEE LLL do, yyyy hh:mm a z')}>
      <span>
        {formatDistance(new Date(date), new Date(), { addSuffix: true })}
      </span>
    </Tooltip>
  );

  return [
    {
      name: 'ASIN',
      data: dealSummaryAdmin?.ASIN || '--'
    },
    {
      name: 'Date added',
      data: dealSummaryAdmin.dateAdded
        ? formatDateTooltip(dealSummaryAdmin.dateAdded)
        : 'missing'
    },
    {
      name: 'Date first active',
      data: dealSummaryAdmin.dateFirstActive
        ? formatDateTooltip(dealSummaryAdmin.dateFirstActive)
        : '--'
    },
    {
      name: 'Last checked',
      data: dealSummaryAdmin.lastChecked
        ? formatDateTooltip(dealSummaryAdmin.lastChecked)
        : '--'
    },
    {
      name: 'Date last sold',
      data: dealSummaryAdmin.dateLastSold
        ? formatDateTooltip(dealSummaryAdmin.dateLastSold)
        : '--'
    },
    {
      name: 'Total sold',
      data: dealSummaryAdmin.totalSold || 0
    },
    {
      name: 'Total sales items',
      data: `${dealSummaryAdmin.totalSalesItems || 0} ${
        dealSummaryAdmin?.totalSalesItems
          ? `(${(
              dealSummaryAdmin?.totalSold / dealSummaryAdmin?.totalSalesItems
            ).toFixed(2)} / per transaction avg)`
          : ''
      }`
    },
    {
      name: 'Name',
      data: dealSummaryAdmin.title
    },
    {
      name: 'Brand',
      data: dealSummaryAdmin.brand
    },
    {
      name: 'Average price sold',
      data: formatPrice(dealSummaryAdmin.averagePrice || 0)
    },
    {
      name: 'Final price',
      data: formatPrice(dealSummaryAdmin.finalPrice || 0)
    },
    {
      name: 'Deal threshold',
      data: formatPrice(dealSummaryAdmin.dealThreshold || 0)
    }
  ];
};
